import liaCarlosAvatar from '../../../../assets/images/userReviews/lia-e-carlos-macedo.jpg';
import antonioAvatar from '../../../../assets/images/userReviews/antonio-marcos-de-lima-melo.jpg';
import elenaAvatar from '../../../../assets/images/userReviews/elena-cardoso.jpg';
import brunoAvatar from '../../../../assets/images/userReviews/bruno-dalto.jpg';
import jessicaAvatar from '../../../../assets/images/userReviews/jessica-vitoria.jpg';
import tarcioAvatar from '../../../../assets/images/userReviews/tarcio-barreira.jpg';

export const userReviewsData = [
  {
    avatar: liaCarlosAvatar,
    author: 'Lia e Carlos Macedo',
    authorOccupation: '',
    comment: `App, muito bom, já fiz recarga, aguardando para por o App em prática, muito intuitivo, até o presente momento tô gostando. Sem bugs"`
  },
  {
    avatar: antonioAvatar,
    author: 'Antonio Marcos de Lima Melo',
    authorOccupation: '',
    comment: `Esse é o aplicativo que mais atende a população de Salvador pois ele explica de forma bem clara onde estão as zonas Azuis da cidade diferente dos demais aplicativos que não identificam tal polígono Onde está marcado Essa zona azul"`
  },
  {
    avatar: elenaAvatar,
    author: 'Elena Cardoso',
    authorOccupation: '',
    comment: `Melhor App... Esse eu confio e recomendo"`
  },
  {
    avatar: brunoAvatar,
    author: 'Samuel Pontes',
    authorOccupation: '',
    comment: `Excelente! Facilitou muito a utilização do rotativo. Tanto para adquirir que no caso é colocar crédito, quanto a utilização em qualquer local! App simples e funcional. Excelente modernização!"`
  },
  {
    avatar: jessicaAvatar,
    author: 'Jessica Vitoria',
    authorOccupation: '',
    comment: `Muito funcional"`
  },
  {
    avatar: tarcioAvatar,
    author: 'Tarcio Barreira',
    authorOccupation: '',
    comment: `Excelente"`
  }
];
