import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Home from '../pages/home';
import GeneralInfo from '../pages/generalInfo';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/generalInfo" component={GeneralInfo} />
  </Switch>
);

export default Routes;
