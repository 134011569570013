import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuoteLeft,
  faLongArrowAltLeft,
  faLongArrowAltRight
} from '@fortawesome/free-solid-svg-icons';

import {
  Container,
  SectionSeparator,
  SectionTitle,
  Content,
  Title,
  QuotationMarkContainer,
  Description,
  Author,
  CurrentAvatar,
  TopLeftAvatar,
  TopRightAvatar,
  LeftTopAvatar,
  LeftBottomAvatar,
  BottomLeftAvatar,
  BottomRightAvatar,
  AuthorOccupation,
  LeftArrowContainer,
  RightArrowContainer
} from './styles';

import liaCarlosAvatar from '../../../../assets/images/userReviews/lia-e-carlos-macedo.jpg';
import antonioAvatar from '../../../../assets/images/userReviews/antonio-marcos-de-lima-melo.jpg';
import elenaAvatar from '../../../../assets/images/userReviews/elena-cardoso.jpg';
import brunoAvatar from '../../../../assets/images/userReviews/bruno-dalto.jpg';
import jessicaAvatar from '../../../../assets/images/userReviews/jessica-vitoria.jpg';
import tarcioAvatar from '../../../../assets/images/userReviews/tarcio-barreira.jpg';

import { userReviewsData } from './data';

const Reviews = () => {
  const [userReviews] = useState(userReviewsData);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);

  const selectNextReview = () => {
    currentSelectedIndex + 1 > userReviews.length - 1
      ? setCurrentSelectedIndex(0)
      : setCurrentSelectedIndex(currentSelectedIndex + 1);
  };

  const selectPreviousReview = () => {
    currentSelectedIndex - 1 < 0
      ? setCurrentSelectedIndex(userReviews.length - 1)
      : setCurrentSelectedIndex(currentSelectedIndex - 1);
  };

  return (
    <Container>
      <SectionSeparator />
      <SectionTitle>Avaliações</SectionTitle>
      <Content>
        <CurrentAvatar avatarImage={userReviews[currentSelectedIndex].avatar} />
        <TopLeftAvatar avatarImage={antonioAvatar} />
        <TopRightAvatar avatarImage={liaCarlosAvatar} />
        <LeftTopAvatar avatarImage={elenaAvatar} />
        <LeftBottomAvatar avatarImage={brunoAvatar} />
        <BottomLeftAvatar avatarImage={jessicaAvatar} />
        <BottomRightAvatar avatarImage={tarcioAvatar} />
        <Title>O que nossos usuários já disseram</Title>
        <QuotationMarkContainer>
          <FontAwesomeIcon icon={faQuoteLeft} size="3x" />
        </QuotationMarkContainer>
        <Description>{userReviews[currentSelectedIndex].comment}</Description>
        <Author>{userReviews[currentSelectedIndex].author}</Author>
        <AuthorOccupation>
          {userReviews[currentSelectedIndex].authorOccupation}
        </AuthorOccupation>
        <LeftArrowContainer onClick={selectPreviousReview}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} size="2x" />
        </LeftArrowContainer>
        <RightArrowContainer onClick={selectNextReview}>
          <FontAwesomeIcon icon={faLongArrowAltRight} size="2x" />
        </RightArrowContainer>
      </Content>
    </Container>
  );
};

export default Reviews;
