import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

import { Container } from './styles';
import DownloadButton from './components/DownloadButton';
import iPhoneMock from '../../../../assets/images/first-view-mockup.png';

const DownloadApp = () => (
  <Container>
    <div className="download-app-info">
      <h1 className="app-info-title">A evolução em estacionamento rotativo</h1>
      <p className="app-info-description">
        Com o app FAZ Digital Salvador você tem comodidade e agilidade na hora
        de estacionar seu veículo nas ruas de Salvador.
      </p>
      <DownloadButton
        icon={faApple}
        topText="Disponível na"
        bottomText="APPLE STORE"
        className={isIOS ? 'button-highlight' : 'button'}
        href="https://itunes.apple.com/br/app/faz-zona-azul-digital-salvador/id1438274937"
      />
      <DownloadButton
        icon={faGooglePlay}
        topText="Disponível na"
        bottomText="PLAY STORE"
        className={isAndroid ? 'button-highlight' : 'button'}
        href="https://play.google.com/store/apps/details?id=com.zona.azul.digital.salvador.estacionamento.rotativo.transalvador"
      />
    </div>

    <div className="download-app-mock">
      <img src={iPhoneMock} alt="iPhone mockup" />
    </div>
  </Container>
);

export default DownloadApp;
